/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/
import '@angular/localize/init';

/** IE9, IE10 and IE11 requires all of the following polyfills. **/	
// NOTE: the 7 -> 8 upgrade script tried to delete these.  Still not sure why.  
import 'core-js/es6/symbol';	
import 'core-js/es6/object';	
import 'core-js/es6/function';	
import 'core-js/es6/parse-int';	
import 'core-js/es6/parse-float';	
import 'core-js/es6/number';	
import 'core-js/es6/math';	
import 'core-js/es6/string';	
import 'core-js/es6/date';	
import 'core-js/es6/array';	
import 'core-js/es6/regexp';	
import 'core-js/es6/map';	
import 'core-js/es6/weak-map';	
import 'core-js/es6/set';


/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window; // uuid module requires window access

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.


/***************************************************************************************************
 * This is needed to resolve "Uncaught ReferenceError: process is not defined" error/bug when 
 * updating to Angular 12, ref: https://github.com/twilio/twilio-client.js/issues/284
 */

(window as any).process = { env: { DEBUG: undefined }, };